<template>
  <v-container fluid>
    <v-card v-if="Object.keys(estudio).length > 0" style="position: relative">
      <v-tabs
        v-model="tab"
        class="elevation-4"
        :style="`position: sticky; z-index: 100; top: ${
          $route.path == '/detalles_estudio' ? '64px' : '0'
        }`"
      >
        <v-tab>Gestión</v-tab>
        <v-tab v-if="$root.acceso('EDITAR_ESTUDIOS')">Gestión interna</v-tab>
        <!-- <v-tab v-if="$root.acceso('EDITAR_ESTUDIOS')">Contactos</v-tab> -->
        <v-tab>Calendario</v-tab>
        <v-tab>Datos</v-tab>
        <v-tab>Geolocalización</v-tab>
        <v-tab>Documentacion</v-tab>
        <v-tab v-if="$root.acceso('EDITAR_ESTUDIOS') && estudio.instalacion">Instalacion</v-tab>
        <v-tab v-if="$root.acceso('EDITAR_ESTUDIOS') && estudio.cargador">Cargador VE</v-tab>
        <v-tab v-if="$root.acceso('EDITAR_ESTUDIOS') && estudio.ventanas.length > 0">Ventanas</v-tab>
        <v-spacer></v-spacer>
        <v-card-actions class="pa-2">
          <v-btn color="secondary" rounded @click.stop="$router.go(-1)">
            <v-icon left>mdi-arrow-left-thick</v-icon>
            Volver
          </v-btn>
          <v-btn
            color="success"
            rounded
            @click.stop="cambiarEstado = true"
            v-if="
              $root.acceso('EDITAR_ESTUDIOS') && estudio.estado_actual.estado == 'Eliminado'
            "
          >
            <v-icon left>mdi-restore</v-icon>
            Restaurar estudio
          </v-btn>
          <v-btn
            color="error"
            rounded
            @click.stop="eliminarEstudio"
            v-if="
              $root.acceso('EDITAR_ESTUDIOS') && estudio.estado_actual.estado != 'Eliminado'
            "
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar estudio
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="getEstudio"
            v-if="$root.acceso('EDITAR_ESTUDIOS')"
          >
            <v-icon left>mdi-refresh</v-icon>
            Recargar
          </v-btn>
          <v-btn
            color="primary"
            rounded
            :to="`/estudios/nuevo?idEstudio=${estudio.idEstudio}`"
          >
            <v-icon left>mdi-pencil</v-icon>EDITAR
          </v-btn>
        </v-card-actions>
      </v-tabs>
      <v-tabs-items class="pa-2" v-model="tab">
        <v-tab-item>
          <GestionEstudio
            :estudio="estudio"
            :idEstudio="idEstudio"
            @reload="getEstudio"
          />
        </v-tab-item>
        <v-tab-item v-if="$root.acceso('EDITAR_ESTUDIOS')">
          <GestionInterna
            :estudio="estudio"
            :idEstudio="idEstudio"
            @reload="getEstudio"
          />
        </v-tab-item>
        <!-- <v-tab-item v-if="$root.acceso('EDITAR_ESTUDIOS')">
          <div
            v-if="estudio.emailComercial || estudio.telefonoComercial"
            class="d-flex flex-wrap ma-2"
            style="gap: 6px"
          >
            <v-chip outlined>
              <v-icon left>mdi-phone</v-icon>
              {{ estudio.telefonoComercial }}
            </v-chip>
            <v-chip outlined>
              <v-icon left>mdi-email</v-icon>
              {{ estudio.emailComercial }}
            </v-chip>
          </div>
          <v-subheader v-else>Sin datos de contacto</v-subheader>
          <v-divider class="my-2" />
          <HistoricoLlamadas :idEstudio="estudio.idEstudio" />
        </v-tab-item> -->
        <v-tab-item>
          <CalendarioEstudio :idEstudio="estudio.idEstudio" />
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                titulo="Información Proyecto"
                icon="format-list-bulleted"
                :datos="[
                  {
                    nombre: 'Nombre proyecto',
                    valor: estudio.nombreProyecto,
                  },
                  {
                    nombre: 'Descripcion proyecto',
                    valor: estudio.descripcion,
                  },
                  { nombre: 'Tipo estudio', valor: estudio.tipo.nombreTipo },
                  {
                    nombre: 'Formato estudio',
                    valor: estudio.formato,
                  },
                  { nombre: 'Latitud', valor: estudio.latitud },
                  { nombre: 'Longitud', valor: estudio.longitud },
                  {
                    nombre: 'Nº Cubiertas',
                    valor: estudio.cubiertas.length || 1,
                  },
                  { nombre: 'Inclinación', valor: estudio.inclinacion },
                  { nombre: 'Azimut', valor: estudio.azimut },
                  {
                    nombre: 'Uralita',
                    valor: estudio.uralita ? 'Sí' : 'No',
                  },
                ]"
              ></tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                titulo="Información Suministro"
                icon="gauge"
                :datos="[
                  ...[estudio.cups_principal].flatMap(({ cups, direccion }, i) => {
                    return [
                      {
                        nombre: 'CUPS ' + (i || ''),
                        valor: cups,
                      },
                      {
                        nombre: 'Dirección',
                        valor: direccion,
                      },
                    ];
                  }),
                  ...estudio.cups.flatMap(({ cups, direccion }, i) => {
                    return [
                      {
                        nombre: 'CUPS ' + (i || ''),
                        valor: cups,
                      },
                      {
                        nombre: 'Dirección',
                        valor: direccion,
                      },
                    ];
                  }),
                ]"
              ></tarjeta-datos>
              <tarjeta-datos
                class="mt-6"
                header
                titulo="Información Cliente"
                icon="account"
                :datos="[
                  { nombre: 'Nombre', valor: estudio?.cliente?.nombre },
                  {
                    nombre: 'Identificador',
                    valor: estudio?.cliente?.identificador,
                  },
                  {
                    nombre: 'Nombre Representante',
                    valor: estudio?.cliente?.representanteNombre,
                  },
                  {
                    nombre: 'Identificador',
                    valor: estudio?.cliente?.representanteDNI,
                  },
                  ...estudio.cliente.contactos.map((c, i) => ({
                    nombre: i == 0 ? 'Contactos' : '',
                    valor: c?.valor,
                  })),
                ]"
              >
                <template v-slot:titulo>
                  <div class="d-flex align-center" style="width: 100%">
                    <h5>Información Cliente</h5>
                  </div>
                </template>
              </tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                titulo="Información Instalación"
                icon="lightning-bolt"
                :datos="[
                  { nombre: 'Tarifa', valor: estudio?.tarifa?.nombreTarifa },
                ]"
              >
                <v-divider />
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Tarifa Periodo</th>
                      <th>Potencia Contratada</th>
                      <th>Precio Potencia</th>
                    </tr>
                  </thead>
                  <tbody v-if="estudio.periodos">
                    <tr
                      v-for="(
                        { idPeriodo, potencia, precio }, key
                      ) in estudio.periodos"
                      :key="key"
                    >
                      <td>{{ idPeriodo }}</td>
                      <td>{{ potencia }}</td>
                      <td>{{ precio }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </tarjeta-datos>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="8">
              <tarjeta-datos header titulo="Mapa" icon="map">
                <div style="min-height: 500px; display: grid">
                  <Map
                    noEdit
                    :center="{
                      lat: Number(estudio.latitud),
                      lng: Number(estudio.longitud),
                    }"
                    :zoom="18"
                    v-model="estudio.cubiertas"
                    :marker="estudio.freezePosition"
                    ref="map"
                  />
                </div>
              </tarjeta-datos>
            </v-col>
            <v-col cols="12" md="4">
              <tarjeta-datos
                header
                icon="google-maps"
                :datos="[
                  { nombre: 'Latitud', valor: estudio.latitud },
                  { nombre: 'Longitud', valor: estudio.longitud },
                ]"
              >
                <template v-slot:titulo>
                  <div>Coordenadas</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    icon
                    color="primary"
                    @click="
                      copyTextWrapper(
                        [estudio.latitud, estudio.longitud].join(', ')
                      )
                    "
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <v-divider />
                <v-subheader>Cubiertas seleccionadas</v-subheader>
                <div class="d-flex flex-column ma-4" style="gap: 16px">
                  <v-card
                    width="100%"
                    v-for="c in cubiertasMarcadas"
                    :key="c.id"
                    class="py-3"
                    @click.stop="
                      $refs.map.panTo({ lat: c.latitud, lng: c.longitud })
                    "
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto"
                        class="d-flex justify-center align-center"
                      >
                        <v-avatar :color="c.color" size="30" class="mx-2" />
                      </v-col>
                      <v-col class="d-flex flex-column mx-2">
                        <h5>{{ c.nombre }}</h5>
                        <h6>{{ c.area }}</h6>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <v-icon small left> mdi-google-maps </v-icon>
                      </v-col>
                      <v-col class="d-flex flex-column mr-2">
                        <span><b>Lat</b>: {{ c.latitud }}</span>
                        <span><b>Lng</b>: {{ c.longitud }}</span>
                      </v-col>
                      <v-col class="d-flex justify-end align-center">
                        <v-btn
                          icon
                          small
                          color="primary"
                          class="mr-1"
                          @click="
                            copyTextWrapper(
                              [c.center.lat, c.center.lng].join(', ')
                            )
                          "
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </tarjeta-datos>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <DocumentacionEstudio :idEstudio="estudio.idEstudio" />
        </v-tab-item>

        <v-tab-item v-if="$root.acceso('EDITAR_ESTUDIOS') && estudio.instalacion ">
          <ResumenInstalacion 
          @reload="getEstudio"
          :id-estudio="estudio.idEstudio"
          />
        </v-tab-item>

        <v-tab-item v-if="$root.acceso('EDITAR_ESTUDIOS') && estudio.cargador ">
          <ResumenCargador 
          @reload="getEstudio"
          :id-estudio="estudio.idEstudio"
          />
        </v-tab-item>
        
        <v-tab-item v-if="$root.acceso('EDITAR_ESTUDIOS') && estudio.ventanas.length > 0 ">
          <ResumenVentanas 
          @reload="getEstudio"
          :id-estudio="estudio.idEstudio"
          />
        </v-tab-item>

      </v-tabs-items>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </v-container>
  
</template>

<script>
import {
  downloadFile,
  parseDate,
  getSituacionIcon,
  timeAgo,
  jsonToCsv,
  copyText,
} from "@/utils/index.js";

export default {
  components: {
    ResumenInstalacion: () => import("../components/kanban/ResumenInstalacion.vue"),
    ResumenCargador: () => import("../components/kanban/ResumenCargador.vue"),
    ResumenVentanas: () => import("../components/kanban/ResumenVentanas.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    Map: () => import("@/components/Map.vue"),
    NuevoMensajeTimeline: () => import("@/components/NuevoMensajeTimeline.vue"),
    HistoricoLlamadas: () => import("../components/llamadas/HistoricoLlamadas.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    GestionEstudio: () => import("../components/gestion/Gestion.vue"),
    GestionInterna: () => import("../components/gestion/GestionInterna.vue"),
    CalendarioEstudio: () => import("../components/calendario/CalendarioEstudio.vue"),
    // DocumentacionEstudio: () => import("../components/DocumentacionEstudio.vue"),
    DocumentacionEstudio: () => import("../components/documentacion/index"),
},
  props: {
    _idEstudio: String | Number,
  },
  data() {
    return {
      tab: 0,
      estudio: {},
      estudioFreeze: {},
      idEstudio: Number(this._idEstudio || this.$route.query.idEstudio),
      addAlertDialog: false,
      cambioTipoEstudio: false,
      cambioComercialEstudio: false,

      loading: false,
    };
  },
  computed: {
    cubiertasMarcadas() {
      if (this.estudio.cubiertas == null || this.estudio.cubiertas.length == 0)
        return [];
      // const cubiertasMarcadas = this.estudio.cubiertas.filter( (c) => c.polygon.visible );
      const cubiertasMarcadas = this.estudio.cubiertas;
      // if (cubiertasMarcadas.length > 0 && !this.estudio.freezePosition) {
      //   // const coords = cubiertasMarcadas[0].polygon
      //   //   .getPath()
      //   //   .getArray()
      //   //   .map((p) => [p.lat(), p.lng()]);
      //   let x = coords.map((xy) => xy[0]);
      //   let y = coords.map((xy) => xy[1]);
      //   let cx = (Math.min(...x) + Math.max(...x)) / 2;
      //   let cy = (Math.min(...y) + Math.max(...y)) / 2;
      //   this.estudio.latitud = cx;
      //   this.estudio.longitud = cy;
      // }
      return cubiertasMarcadas;
    },
  },
  mounted() {
    if (this.idEstudio == null) return;
    this.getEstudio();
  },
  methods: {
    getSituacionIcon,
    parseDate,
    timeAgo,
    copyTextWrapper(text) {
      copyText(text)
        .then(() => this.$root.$emit("snack", "Copiado al portapapeles"))
        .catch(() =>
          this.$root.$emit("snack", "No se ha podido copiar al portapapeles")
        );
    },
    async getEstudio() {
      this.loading = true;
      const { data: estudio } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
      });

      this.estudio = estudio;

      this.loading = false;
    },
    async eliminarEstudio() {
      // if (!confirm("¿Estás seguro de que quieres eliminar este estudio?"))
      //   return;

      const motivo = await this.$refs.confirm.open(
        "Eliminar estudio",
        "¿Estás seguro de que quieres eliminar este estudio?",
        { confirmColor: "error", width: 600, addExplanation: true }
      );
      if (!motivo) return;

      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
        data: {
          motivo,
        },
      }).then((res) => {
        this.getEstudio();
        // this.$router.push({ name: "Estudios" });
      });
    },
    async toggleFav() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/favoritos`,
        data: {
          idEstudio: this.estudio.idEstudio,
        },
      });
      this.getEstudio();
      this.$root.$emit(
        "snack",
        `Estudio ${this.estudio.idEstudio} ${
          this.estudio.fav ? "eliminado de" : "añadido a"
        } favoritos`
      );
      this.$root.$emit("getFav");
    },
  },
};
</script>
