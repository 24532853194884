var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(Object.keys(_vm.estudio).length > 0)?_c('v-card',{staticStyle:{"position":"relative"}},[_c('v-tabs',{staticClass:"elevation-4",style:(`position: sticky; z-index: 100; top: ${
        _vm.$route.path == '/detalles_estudio' ? '64px' : '0'
      }`),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Gestión")]),(_vm.$root.acceso('EDITAR_ESTUDIOS'))?_c('v-tab',[_vm._v("Gestión interna")]):_vm._e(),_c('v-tab',[_vm._v("Calendario")]),_c('v-tab',[_vm._v("Datos")]),_c('v-tab',[_vm._v("Geolocalización")]),_c('v-tab',[_vm._v("Documentacion")]),(_vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.instalacion)?_c('v-tab',[_vm._v("Instalacion")]):_vm._e(),(_vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.cargador)?_c('v-tab',[_vm._v("Cargador VE")]):_vm._e(),(_vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.ventanas.length > 0)?_c('v-tab',[_vm._v("Ventanas")]):_vm._e(),_c('v-spacer'),_c('v-card-actions',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"color":"secondary","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-thick")]),_vm._v(" Volver ")],1),(
            _vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.estado_actual.estado == 'Eliminado'
          )?_c('v-btn',{attrs:{"color":"success","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.cambiarEstado = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-restore")]),_vm._v(" Restaurar estudio ")],1):_vm._e(),(
            _vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.estado_actual.estado != 'Eliminado'
          )?_c('v-btn',{attrs:{"color":"error","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.eliminarEstudio.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar estudio ")],1):_vm._e(),(_vm.$root.acceso('EDITAR_ESTUDIOS'))?_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.getEstudio}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Recargar ")],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","rounded":"","to":`/estudios/nuevo?idEstudio=${_vm.estudio.idEstudio}`}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("EDITAR ")],1)],1)],1),_c('v-tabs-items',{staticClass:"pa-2",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('GestionEstudio',{attrs:{"estudio":_vm.estudio,"idEstudio":_vm.idEstudio},on:{"reload":_vm.getEstudio}})],1),(_vm.$root.acceso('EDITAR_ESTUDIOS'))?_c('v-tab-item',[_c('GestionInterna',{attrs:{"estudio":_vm.estudio,"idEstudio":_vm.idEstudio},on:{"reload":_vm.getEstudio}})],1):_vm._e(),_c('v-tab-item',[_c('CalendarioEstudio',{attrs:{"idEstudio":_vm.estudio.idEstudio}})],1),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Información Proyecto","icon":"format-list-bulleted","datos":[
                {
                  nombre: 'Nombre proyecto',
                  valor: _vm.estudio.nombreProyecto,
                },
                {
                  nombre: 'Descripcion proyecto',
                  valor: _vm.estudio.descripcion,
                },
                { nombre: 'Tipo estudio', valor: _vm.estudio.tipo.nombreTipo },
                {
                  nombre: 'Formato estudio',
                  valor: _vm.estudio.formato,
                },
                { nombre: 'Latitud', valor: _vm.estudio.latitud },
                { nombre: 'Longitud', valor: _vm.estudio.longitud },
                {
                  nombre: 'Nº Cubiertas',
                  valor: _vm.estudio.cubiertas.length || 1,
                },
                { nombre: 'Inclinación', valor: _vm.estudio.inclinacion },
                { nombre: 'Azimut', valor: _vm.estudio.azimut },
                {
                  nombre: 'Uralita',
                  valor: _vm.estudio.uralita ? 'Sí' : 'No',
                },
              ]}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Información Suministro","icon":"gauge","datos":[
                ...[_vm.estudio.cups_principal].flatMap(({ cups, direccion }, i) => {
                  return [
                    {
                      nombre: 'CUPS ' + (i || ''),
                      valor: cups,
                    },
                    {
                      nombre: 'Dirección',
                      valor: direccion,
                    },
                  ];
                }),
                ..._vm.estudio.cups.flatMap(({ cups, direccion }, i) => {
                  return [
                    {
                      nombre: 'CUPS ' + (i || ''),
                      valor: cups,
                    },
                    {
                      nombre: 'Dirección',
                      valor: direccion,
                    },
                  ];
                }),
              ]}}),_c('tarjeta-datos',{staticClass:"mt-6",attrs:{"header":"","titulo":"Información Cliente","icon":"account","datos":[
                { nombre: 'Nombre', valor: _vm.estudio?.cliente?.nombre },
                {
                  nombre: 'Identificador',
                  valor: _vm.estudio?.cliente?.identificador,
                },
                {
                  nombre: 'Nombre Representante',
                  valor: _vm.estudio?.cliente?.representanteNombre,
                },
                {
                  nombre: 'Identificador',
                  valor: _vm.estudio?.cliente?.representanteDNI,
                },
                ..._vm.estudio.cliente.contactos.map((c, i) => ({
                  nombre: i == 0 ? 'Contactos' : '',
                  valor: c?.valor,
                })),
              ]},scopedSlots:_vm._u([{key:"titulo",fn:function(){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('h5',[_vm._v("Información Cliente")])])]},proxy:true}],null,false,3646470363)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Información Instalación","icon":"lightning-bolt","datos":[
                { nombre: 'Tarifa', valor: _vm.estudio?.tarifa?.nombreTarifa },
              ]}},[_c('v-divider'),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Tarifa Periodo")]),_c('th',[_vm._v("Potencia Contratada")]),_c('th',[_vm._v("Precio Potencia")])])]),(_vm.estudio.periodos)?_c('tbody',_vm._l((_vm.estudio.periodos),function({ idPeriodo, potencia, precio },key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(idPeriodo))]),_c('td',[_vm._v(_vm._s(potencia))]),_c('td',[_vm._v(_vm._s(precio))])])}),0):_vm._e()])],1)],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Mapa","icon":"map"}},[_c('div',{staticStyle:{"min-height":"500px","display":"grid"}},[_c('Map',{ref:"map",attrs:{"noEdit":"","center":{
                    lat: Number(_vm.estudio.latitud),
                    lng: Number(_vm.estudio.longitud),
                  },"zoom":18,"marker":_vm.estudio.freezePosition},model:{value:(_vm.estudio.cubiertas),callback:function ($$v) {_vm.$set(_vm.estudio, "cubiertas", $$v)},expression:"estudio.cubiertas"}})],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tarjeta-datos',{attrs:{"header":"","icon":"google-maps","datos":[
                { nombre: 'Latitud', valor: _vm.estudio.latitud },
                { nombre: 'Longitud', valor: _vm.estudio.longitud },
              ]},scopedSlots:_vm._u([{key:"titulo",fn:function(){return [_c('div',[_vm._v("Coordenadas")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.copyTextWrapper(
                      [_vm.estudio.latitud, _vm.estudio.longitud].join(', ')
                    )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]},proxy:true}],null,false,2591885949)},[_c('v-divider'),_c('v-subheader',[_vm._v("Cubiertas seleccionadas")]),_c('div',{staticClass:"d-flex flex-column ma-4",staticStyle:{"gap":"16px"}},_vm._l((_vm.cubiertasMarcadas),function(c){return _c('v-card',{key:c.id,staticClass:"py-3",attrs:{"width":"100%"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.map.panTo({ lat: c.latitud, lng: c.longitud })}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"auto"}},[_c('v-avatar',{staticClass:"mx-2",attrs:{"color":c.color,"size":"30"}})],1),_c('v-col',{staticClass:"d-flex flex-column mx-2"},[_c('h5',[_vm._v(_vm._s(c.nombre))]),_c('h6',[_vm._v(_vm._s(c.area))])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-google-maps ")])],1),_c('v-col',{staticClass:"d-flex flex-column mr-2"},[_c('span',[_c('b',[_vm._v("Lat")]),_vm._v(": "+_vm._s(c.latitud))]),_c('span',[_c('b',[_vm._v("Lng")]),_vm._v(": "+_vm._s(c.longitud))])]),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){_vm.copyTextWrapper(
                            [c.center.lat, c.center.lng].join(', ')
                          )}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-tab-item',[_c('DocumentacionEstudio',{attrs:{"idEstudio":_vm.estudio.idEstudio}})],1),(_vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.instalacion )?_c('v-tab-item',[_c('ResumenInstalacion',{attrs:{"id-estudio":_vm.estudio.idEstudio},on:{"reload":_vm.getEstudio}})],1):_vm._e(),(_vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.cargador )?_c('v-tab-item',[_c('ResumenCargador',{attrs:{"id-estudio":_vm.estudio.idEstudio},on:{"reload":_vm.getEstudio}})],1):_vm._e(),(_vm.$root.acceso('EDITAR_ESTUDIOS') && _vm.estudio.ventanas.length > 0 )?_c('v-tab-item',[_c('ResumenVentanas',{attrs:{"id-estudio":_vm.estudio.idEstudio},on:{"reload":_vm.getEstudio}})],1):_vm._e()],1)],1):_vm._e(),_c('ConfirmDialog',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }